<template>

	<div class="playlist-box" v-bind:class="{selected :  selected }">


 

    		<div class="thumb">

                <v-image
                    :gradient="false"
                    :src="src"
                    v-if="src"
                ></v-image>
                

            <v-button
                class="abs-top-left"
                :icon="'_icon-right'"
                :bold="true"
                :label="label"
                v-if="!direction"
            >{{name}}</v-button>

            <v-button
                class="abs-bottom-right"
                :icon="'icon-right'"
                v-if="!direction"
            >{{cta}}</v-button>

    	    </div>

  

	    
	</div>

</template>

<script>

	import Image from '@/Components/partials/Image';
	import Button from '@/Components/partials/Button';

	export default {
		name : 'PlaylistBox',
		props : [  'src', 'href', 'name', 'label', 'cta', 'direction', 'selected' ],
		components: {
            'v-image': Image,
            'v-button': Button,
        }
	}
</script>