<template>

  	<div id="playlists">

        <article class="page half">

            <div class="row fill-center">

                <div class="col twothirds">

                    <h2 class="bolder">Playlists</h2>

                    <br>

                    <p>Explore different eras of Factory Records’ pioneering sound with these special  playlists made to accompany the Use Hearing Protection exhibition.</p>

                </div>

            </div>

        </article>

        <article class="page playlist full black" v-if="playlist">

            <div class="row fill-center">

                <div class="col twothirds">

                    <div class="row">

                        <div class="col">

                            <v-image
                                :gradient="false"
                                :src="'/uploads/' + playlist.cover"
                            ></v-image>

                            <br>
                                    
                            <h2 class="white bolder">
                                {{playlist.title}}
                            </h2>

                            <br>

                            <p class="white">{{playlist.body}}</p>

                            <br>

                            <a :href="playlist.link" target="_blank"><h5 class="bold white underline">Open this playlist</h5></a>

                        </div>

                        <div class="col">

                            <v-playlist-block
                                v-on:previous-playlist="onPreviousPlaylist"
                                v-on:next-playlist="onNextPlaylist"
                                :playlist="playlist"
                            ></v-playlist-block>

                        </div>

                    </div>
  
                </div>

            </div>

        </article>

        <article class="page grid grey-lighter" v-if="playlist">

            <div class="row" v-for="playlists in chunkedPlaylists">

                <div class="col" v-on:click="onClickPlaylist(item.id)" v-for="(item, key, index) in playlists" :key="index">

                    <v-playlist-box
                        v-bind:class="{ selected : item.id == playlist.id}"
                        :selected="item.id == playlist.id"
                        :name="item.title"
                        :cta="'Play'"
                        :src="'/uploads/' + item.cover"
                    ></v-playlist-box>

               </div>

                <div class="col" v-if="playlists.length < chuck" v-for="n in (chuck - playlists.length)">
           
               </div>

             
            </div>

        </article>

    </div>

</template>

<script>


    import axios from 'axios';
    import VueAxios from 'vue-axios';

    import Button from '@/Components/partials/Button';
    import Image from '@/Components/partials/Image';
    import PlaylistBlock from '@/Components/partials/PlaylistBlock';
    import PlaylistBox from '@/Components/partials/PlaylistBox';
   

    /* Chuck https://www.npmjs.com/package/chunk */
    let chunk = require('chunk');

	export default {
	    name: 'Playlists',
        props:[ 'links'],
        data: () => ({
            forceupdate : false,
            playlists : false,
            playlist : false,
            chuck : 4,
            placeholder : {
                video : 'https://placeimg.com/854/480/anima/grayscale', //16:9
                square : 'https://placeimg.com/640/640/animal/grayscale',
                rectangle : 'https://placeimg.com/844/616/animal/grayscale'
            }
        }),

        components: {
            'v-button': Button,
            'v-image': Image,
            'v-playlist-block': PlaylistBlock,
            'v-playlist-box': PlaylistBox,
        },

        /*
        FOR LATER: API NOTES
        https://developer.spotify.com/documentation/web-api/reference/playlists/get-playlist-cover/
        */

        watch : {

            forceupdate(value) {

                /* So the user can change the fac in the bottom nav while listening to the player */

                this.forceupdate = false;
                let self = this;
                this.$nextTick(() => {
                    this.playlist = this.getPlaylist( self.$route.params.slug );
                });


                 //this.$root.$emit('scrollTop'); //Do this on each route change now

            },

        },

        created() {


            let self = this;
            this.getPlaylists( function( data ){
                self.playlists = data;
                self.playlist = (self.$route.params.slug) ? self.getPlaylist( self.$route.params.slug ) : self.playlists[0];

                if( !self.$route.params.slug ){
                    self.updateUrl();
                }
            });

        },

        computed: {
            chunkedPlaylists() {
                return chunk(this.playlists, this.chuck)
            }
        },

        methods: {

            getPlaylists:function( callback ){

                /*
                let data = require('/data/playlists.json');
                return (data) ? data : false; 
                */
                axios.get('/data/playlists.json').then(response => {
                    let data = response.data.playlists;
                    if ( data && callback ) callback(data);
                });
            },

            getPlaylist:function( slug ){

                if( !this.playlists ){
                    return false;
                }

                let data = this.playlists.filter(function(item) {
                    return item.slug == slug;
                });

                return ( data[0] ) ? data[0] : false;
            },

            updateUrl:function( ){
                this.$router.push("/playlists/" + this.playlist.slug );
                this.forceupdate = true;
            },

            onPreviousPlaylist:function(){


                let previous = this.playlist.id - 2;

                this.playlist = ( this.playlists[previous] ) ? this.playlists[previous] : this.playlists[this.playlists.length-1];

                this.updateUrl();
            },

            onClickPlaylist:function( id ){

                //console.log( 'onClickPlaylist', id );

                let next = id - 1;

                this.playlist = ( this.playlists[next] ) ? this.playlists[next] : this.playlists[0];

                this.updateUrl();
            },

            onNextPlaylist:function(){

                let next = this.playlist.id;

                this.playlist = ( this.playlists[next] ) ? this.playlists[next] : this.playlists[0];

                this.updateUrl();
            },

        }
	}
</script>