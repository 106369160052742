<template>

	<div class="icons share" v-bind:class="{right : right}">

        <ul>

            <li class="facebook" v-on:click="onShare('facebook')">
                <div>
                    <i class="icon-facebook"></i>
                </div>
            </li>

            <li class="twitter" v-on:click="onShare('twitter')">
                <div>
                    <i class="icon-twitter"></i>
                </div>

            </li>

            <li class="link" v-on:click="onCopyLink">
                <div>
                    <input type="hidden" id="copyme" :value="sharelink" v-if="sharelink">
                    <i class="icon-link"></i>
                    <p class="tag sm" v-if="copied">copied</p>
                </div>
            </li>

        </ul>

    </div>

</template>

<script>

	export default {

		name : 'NetworksShare',
		props : [  'share', 'links', 'right' ],

        data: () => ({
            sharelink : false,
            copied : false
        }),

        created() {


            this.sharelink = this.share.url + this.$route.path;
        },

        methods :{

            onCopyLink : function() {

                let el = document.querySelector('#copyme');
                el.setAttribute('type', 'text');
                el.select();

                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    this.copied = true;
                    var self = this;
                    setTimeout(function(){
                        self.copied = false;
                    }, 2000);
                    //console.log('onCopyLink: ' + msg);
                } catch (err) {
                    //console.log('onCopyLink: Oops, unable to copy');
                }

                el.setAttribute('type', 'hidden');
                window.getSelection().removeAllRanges();
            },

            onShare: function ( network ) {

                var url, params;

                url = false;

                switch(network){

                    case 'twitter':

                        url = "http://twitter.com/share?";
                        
                        params = {
                            url: this.sharelink, 
                            text: this.share.copy,
                            //via: this.share.via,
                            hashtags: this.share.hashtag
                        }

                    break;

                    case 'facebook':

                        url = 'https://www.facebook.com/sharer/sharer.php?';

                        params = {
                            u: this.sharelink, 
                        }

                    break;
               }

               //console.log('onShare', url );

               if(url){
                    for(var prop in params) url += '&' + prop + '=' + encodeURIComponent(params[prop]);
                    window.open(url, '', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
               }


            },
        }
	}
</script>