<template>

	<div class="image-box">

		<div class="thumb" v-bind:class="{ fill : fill }">

            <a :href="href" v-on:click.prevent="$root.$emit('click', href)" target="_self" v-if="href && src">
                <v-image
                    :gradient="gradient"
                    :src="src"
                    v-if="src"
                ></v-image>
            </a>

	        <v-image
	        	:gradient="gradient"
                :src="src"
                v-if="!href && src"
            ></v-image>


            <a :href="href" target="_self" v-on:click.prevent="$root.$emit('click', href)" v-if="href && !src"></a>

            <!-- DOWN -->

<!--             <v-button
	        	class="white abs-top-left"
	        	:icon="'icon-level-down'"
                :bold="true"
                :label="label"
                v-if="direction == 'down'"
            >{{name}}</v-button>

            <v-button
            	class="white abs-bottom-right"
            	:icon="'icon-down'"
            	v-if="direction == 'down'"
            >{{cta}}</v-button>
 -->

            <!-- UP -->

<!--             <v-button
	        	class="white abs-top-left"
	        	:icon="'icon-level-up'"
                :bold="true"
                :label="label"
                v-if="direction == 'up'"
            >{{name}}</v-button>

            <v-button
            	class="white abs-bottom-right "
            	:icon="'icon-up'"
            	v-if="direction == 'up'"
            >{{cta}}</v-button> -->


            <!-- RIGHT -->

<!--             <v-button
	        	class="white abs-top-left"
	        	:icon="'icon-level-right'"
                :bold="true"
                :label="label"
                v-if="direction == 'right'"
            >{{name}}</v-button>

            <v-button
            	class="white abs-bottom-right"
            	:icon="'icon-right'"
            	v-if="direction == 'right'"
            >{{cta}}</v-button>
 -->
            <!-- LEFT -->

<!-- 
            <v-button
	        	class="white abs-top-left"
	        	:icon="'icon-level-left'"
                :bold="true"
                :label="label"
                v-if="direction == 'left'"
            >{{name}}</v-button>

            <v-button
            	class="white abs-bottom-left"
            	:icon="'icon-left'"
            	v-if="direction == 'left'"
            >{{cta}}</v-button>

 -->
            <!-- DEFAULT -->


	        <v-button
	        	class="abs-top-left"
	        	:icon="'_icon-right'"
                :bold="true"
                :label="label"
                v-if="!direction"
            >{{name}}</v-button>

            <v-button
            	class="abs-bottom-right"
            	:icon="'icon-right'"
            	v-if="!direction"
            >{{cta}}</v-button>

	    </div>

	    
	</div>

</template>

<script>

	import Image from '@/Components/partials/Image';
	import Button from '@/Components/partials/Button';

	export default {
		name : 'ImageBox',
		//props : [  'src', 'href', 'name', 'label', 'cta', 'fill', 'direction', 'gradient'],

        props: {

            src: {
                type: String,
                default: null
            },

            href: {
                type: String,

                default: null
            },

            label: {
                type: String,

                default: null
            },

            name: {
                value: Number | String | Array,
                default: null
            },

            cta: {
                type: String,
                default: null
            },

            direction: {
                type: String,
                default: null
            },


            fill: {
                type: Boolean,
                default: false
            },
            gradient: {
                type: Boolean,
                default: true
            }
        },

		data: () => ({
            active : false
        }),
		components: {
            'v-image': Image,
            'v-button': Button,
        }
	}
</script>