<template>

  	<div id="stylekit">

    
        <!-- HERO-------------------->

        <div class="page hero">

            <div class="row">

                <div class="col three">
                    
                    <v-book-end
                        class="left"
                        :more="'Chelsea Space 13/9 - 26/10'"
                    >FAC 1 - 50 / 40</v-book-end>
    
                    <v-button
                        class="abs-bottom-left"
                        :label="'Companion'"
                    >Exhibition</v-button>

                </div>

                <div class="col">

                    <!-- <h2 class="heading bold upcase">Celebrating the life and legacy of Factory Records.</h2>

                    <p class="text">2019 marks the 40th anniversary of Factory Records and will be marked by the release of 'Use Hearing Protection' and a 40th anniversary exhibition.</p> -->
                    <br>
                    <h3 class="right lg">Tony</h3>
                    <br>
                    <h3 class="right lg">Alan</h3>
                    <br>
                    <h3 class="right lg">Peter</h3>
                    <br>
                    <h3 class="right lg">Martin</h3>
                    <br>
                    <h3 class="right lg">Rob</h3>
                    <br>
                    

                    <img class="logo" src="@/assets/images/use-hearing-protection.png">

                </div>

            </div>

           

            <!-- <v-book-end
                class="right"
                >1978 - 1992</v-book-end> -->

        </div>



        <div class="page hero">

            <div class="row">

                <div class="col three">
                    

                    <v-book-end
                        class="left"
                        :outline="true"
                    >News</v-book-end>

              

                </div>


                <div class="col">

                    
                    <h4 class="right lg">Updates on the Use Hearing Protection FAC 1 – 50 / 40 exhibition. Don’t miss anything and sign up to Factory Communications</h4>
                    
                     <v-newsletter></v-newsletter>

                    

                </div>

               

            </div>

           

        </div>


        <!-- BAR SHOWCASE-------------------->

        <div class="page showcase border-top">

           <v-grill
            class="left top abs-top-left"
           ></v-grill>

          <!--  <v-grill
            class="right abs-bottom-right"
           ></v-grill> -->

            <div class="row fill-center">

                <div class="col">


                    <div class="text bars">

                        <h5 class="bold">Use Hearing Protection Box Set</h5>
                        <br>
                        <p>Use Hearing Protection' is a limited edition box set including the first 10 FAC items, with a 52-page exhibition style book, plus a bonus 12” single and 2CDs of unheard Joy Division interviews. The box will be designed by Factory director Peter Saville.</p>
                    </div>

                    <p class="caption grey outline">only 4000 will be made.</p>
                    <br>
                    <v-button
                        class="preorder"
                        :label="'Now'"
                    >PreOrder</v-button>

                </div>

            </div>

        </div>


        <!-- NO BAR SHOWCASE-------------------->

        <div class="page left-gap showcase border-top border-left border-bottom">

            <v-grill
            class="right top abs-top-right"
            ></v-grill>

            <div class="row fill-center">

                <div class="col three">

                    <div class="text">

                        <h5 class="bold">Use Hearing Protection Box Set</h5>
                        <br>
                        <p>Use Hearing Protection' is a limited edition box set including the first 10 FAC items, with a 52-page exhibition style book, plus a bonus 12” single and 2CDs of unheard Joy Division interviews. The box will be designed by Factory director Peter Saville.</p>
                    </div>

                </div>

                <div class="col three"></div>

                <div class="col">
                    <h2 class="right">Search<br>FAC</h2>



                    <v-search></v-search>

                </div>

                <div class="col"></div>

            </div>


        </div>


        <!-- GRID-------------------->


        <div class="page">

    
            <v-title
                class="left top abs-top-left"
               >Discover</v-title>


            <div class="row gap">

                <div class="col">
                    <v-video-block
                        :border="true"
                        :cta="'Play Video'"
                        :youtube="'LS7CiL8MNb0'"
                        :poster="placeholder.video"
                    ></v-video-block>
                </div>

            </div>

            <div class="row space">
            
                <div class="col">

                    <v-image-block
                        :cta="'Test'"
                        :src="placeholder.square"
                    ></v-image-block>

                </div>

                <div class="col">
                    <v-image-block
                        :cta="'Test'"
                        :src="placeholder.square"
                    ></v-image-block>
                </div>

                <div class="col">
                    <v-image-block
                        :cta="'Test'"
                        :src="placeholder.square"
                    ></v-image-block>
                </div>

                <div class="col">
                    <v-image-block
                        :cta="'Test'"
                        :src="placeholder.square"
                    ></v-image-block>
                </div>

            </div>

            

        </div>





        <!-- BLOCKS-------------------->

        <div class="page">

            <div class="row space">
                
                <div class="col">

                    <v-image-block
                        :underline="true"
                        :date="'01.01.2019'"
                        :caption="true"
                        :cta="'Read'"
                        :src="placeholder.video"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</v-image-block>

                </div>

                <div class="col">
                    <v-image-block
                        :underline="true"
                        :date="'01.01.2019'"
                        :cta="'Read'"
                        :caption="true"
                        :src="placeholder.video"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</v-image-block>
                </div>

                <div class="col">
                    <v-image-block
                        :underline="true"
                        :date="'01.01.2019'"
                        :cta="'Read'"
                        :caption="true"
                        :src="placeholder.video"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</v-image-block>
                </div>

                

            </div> 

            <div class="row space">
                
                <div class="col">

                    <v-image-block
                        :underline="true"
                        :date="'01.01.2019'"
                        :caption="true"
                        :cta="'Read'"
                        :src="placeholder.video"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</v-image-block>

                </div>

                <div class="col">
                    <v-image-block
                        :underline="true"
                        :date="'01.01.2019'"
                        :cta="'Read'"
                        :caption="true"
                        :src="placeholder.video"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</v-image-block>
                </div>

                <div class="col">
                    <v-image-block
                        :underline="true"
                        :date="'01.01.2019'"
                        :cta="'Read'"
                        :caption="true"
                        :src="placeholder.video"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</v-image-block>
                </div>

              

            </div>  


            <div class="row right">
                
                <div class="col third">

                    <v-image-block
                        :underline="true"
                        :date="'01.01.2019'"
                        :cta="'Read'"
                        :title="'Next Article'"
                        :caption="true"
                        :src="placeholder.video"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</v-image-block>
                </div>

            </div>

            <div class="row left">

                <div class="col sixth"> 

                   <v-image-box
                        :name="'Page'"
                        :label="'Previous'"
                        :fill="true"
                        :direction="'left'"
                    ></v-image-box>

                </div>

            </div>   


            <div class="row right">

                <div class="col sixth"> 

                   <v-image-box
                        :name="'Page'"
                        :label="'Next'"
                        :fill="true"
                        :direction="'right'"
                    ></v-image-box>

                </div>

            </div>   


            <div class="row center">


                <div class="col sixth"> 

                   <v-image-box
                        :name="'More'"
                        :label="'Load'"
                        :fill="true"
                        :direction="'down'"
                    ></v-image-box>

                </div>

            </div>   


            <div class="row right">

                <div class="col sixth"> 

                   <v-image-box
                        :name="'Top'"
                        :label="'Back to'"
                        :fill="true"
                        :direction="'up'"
                    ></v-image-box>

                 </div>

            </div>   

        </div>







        <!-- ARTICLE -------------------->

        <div class="page article">


            <div class="row">

                <div class="col"></div>


                 <div class="col three border-bottom-thick">

                    <v-image
                        :gradient="false"
                        :src="placeholder.rectangle"
                    ></v-image>


                    <br>

                    <h2 class="title">Article with a long title can go here and still look good on next line</h2>

                    <br>

                    <div class="body">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <br>

                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>

                         <br>

                        <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>


                         <br>


                        <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatu</p>

                         <br>

                        <v-image
                            :gradient="false"
                            :src="placeholder.rectangle"
                        ></v-image>

                        <br>

                        <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>


                        <br>


                        <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatu</p>


                        <br>

                        


                    </div>

                    <p class="icons-label bold">Share</p>

                    <v-networks-share
                        :links="links"
                    ></v-networks-share>


                    <br>
                    <br>

                </div>

                 <div class="col"></div>
            </div>


        </div>





        <!-- BOXES-------------------->

          <div class="page">

            <div class="row space">
                
                <div class="col">

                    <v-image-box
                        :name="1"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="2"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="3"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="4"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="5"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>

                <div class="col">

                    <v-image-box
                        :name="6"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>

            </div>   


            <div class="row space">
                
                <div class="col">

                    <v-image-box
                        :name="7"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="8"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="8"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>
                </div>


                <div class="col">

                     <v-image-box
                        :name="9"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="10"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="11"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>

            </div>  



            <div class="row space">
                
                <div class="col">

                    <v-image-box
                        :name="12"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="13"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="14"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>
                </div>


                <div class="col">

                     <v-image-box
                        :name="15"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="16"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="17"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>

            </div>  





             <div class="row space">
                
                <div class="col">

                    <v-image-box
                        :name="18"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="19"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="20"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>
                </div>


                <div class="col">

                     <v-image-box
                        :name="21"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="22"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="23"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>

            </div>  


             <div class="row space">
                
                <div class="col">

                    <v-image-box
                        :name="24"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="25"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="26"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>
                </div>


                <div class="col">

                     <v-image-box
                        :name="27"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="28"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="29"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>

            </div>  


             <div class="row space">
                
                <div class="col">

                    <v-image-box
                        :name="30"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="31"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="32"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>
                </div>


                <div class="col">

                     <v-image-box
                        :name="33"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="34"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="35"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>

            </div>  




             <div class="row space">
                
                <div class="col">

                    <v-image-box
                        :name="36"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="37"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="38"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>
                </div>


                <div class="col">

                     <v-image-box
                        :name="39"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="40"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="41"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>

            </div>  


             <div class="row space">
                
                <div class="col">

                    <v-image-box
                        :name="42"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="43"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="44"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>
                </div>


                <div class="col">

                     <v-image-box
                        :name="45"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="46"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>


                <div class="col">

                     <v-image-box
                        :name="47"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                    ></v-image-box>

                </div>

            </div>  


             <div class="row space">
                
                <div class="col">

                    <v-image-box
                        :name="48"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="49"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>

                </div>


                <div class="col">

                    <v-image-box
                        :name="50"
                        :label="'FAC'"
                        :cta="'Show'"
                        :src="placeholder.square"
                        ></v-image-box>
                </div>


                <div class="col">

                     
                </div>


                <div class="col">

                    

                </div>


                <div class="col">

                     <v-image-box
                        :name="'Top'"
                        :label="'Back to'"
                        :fill="true"
                        :direction="'up'"
                    ></v-image-box>

                </div>

            </div> 

        </div>

        <div class="page">
            
            <div class="icons" style="width:100%;">
                
                <ul>
                    <li><a><i class="icon-down-dir"></i></a></li>
                    <li><a><i class="icon-left-dir"></i></a></li>
                    <li><a><i class="icon-right-dir"></i></a></li>
                    <li><a><i class="icon-up-dir"></i></a></li>
                    <li><a><i class="icon-slash"></i></a></li>
                    <li><a><i class="icon-save"></i></a></li>
                    <li><a><i class="icon-factory"></i></a></li>
                    <li><a><i class="icon-close"></i></a></li>
                    <li><a><i class="icon-envelope"></i></a></li>
                    <li><a><i class="icon-expand-cross"></i></a></li>
                    <li><a><i class="icon-little-square"></i></a></li>
                    <li><a><i class="icon-play-button"></i></a></li>
                    <li><a><i class="icon-skip-back"></i></a></li>
                    <li><a><i class="icon-skip-forward"></i></a></li>
                    <li><a><i class="icon-pause-button"></i></a></li>

                    <li><a><i class="keyboard-arrow-up"></i></a></li>
                    <li><a><i class="icon-em-made-me-do-it"></i></a></li>
                    <li><a><i class="icon-keyboard-arrow-down"></i></a></li>
                    <li><a><i class="icon-twitter"></i></a></li>
                    <li><a><i class="icon-facebook"></i></a></li>
                    <li><a><i class="icon-level-up"></i></a></li>
                    <li><a><i class="icon-level-down"></i></a></li>
                    <li><a><i class="icon-youtube-play"></i></a></li>
                    <li><a><i class="icon-down"></i></a></li>
                    <li><a><i class="icon-up"></i></a></li>
                    <li><a><i class="icon-left"></i></a></li>
                    <li><a><i class="icon-right"></i></a></li>
                    <li><a><i class="icon-link"></i></a></li>
                    <li><a><i class="icon-four-squares"></i></a></li>

                </ul>

            </div>

            <br>


             <div class="icons">
                
                <ul>
                    <li><a><i class="icon-down-dir"></i></a></li>
                </ul>

            </div>


             <br>

            <div class="icons right">
                
                <ul>
                    <li><a><i class="icon-down-dir"></i></a></li>
                    <li><a><i class="icon-down-dir"></i></a></li>
                </ul>

            </div>

             <br>

            <div class="icons">
                
                <ul>
                    <li><a><i class="icon-down-dir"></i></a></li>
                    <li><a><i class="icon-down-dir"></i></a></li>
                    <li><a><i class="icon-down-dir"></i></a></li>
                </ul>

            </div>

             <br>

            <div class="icons right">
                
                <ul>
                    <li><a><i class="icon-down-dir"></i></a></li>
                    <li><a><i class="icon-down-dir"></i></a></li>
                    <li><a><i class="icon-down-dir"></i></a></li>
                    <li><a><i class="icon-down-dir"></i></a></li>
                </ul>

            </div>

            <br>
            
            <v-networks-connect
                :links="links"
            ></v-networks-connect>

            <br>

            <v-networks-share
                :links="links"
            ></v-networks-share>

        </div>







        <!-- END OF STYLEKIT -->
    </div>

</template>

<script>

    import Grill from '@/Components/partials/Grill';
    import Title from '@/Components/partials/Title';
    import Button from '@/Components/partials/Button';
    import Image from '@/Components/partials/Image';
    import ArtistBlock from '@/Components/partials/ArtistBlock';
    import ImageBlock from '@/Components/partials/ImageBlock';
    import VideoBlock from '@/Components/partials/VideoBlock';
    import ImageBox from '@/Components/partials/ImageBox';
    import TweetBox from '@/Components/partials/TweetBox';
    import BookEnd from '@/Components/partials/BookEnd';
    import NewsLetter from '@/Components/partials/NewsLetter';
    import Search from '@/Components/partials/Search';
    import NetworksConnect from '@/Components/partials/NetworksConnect';
	import NetworksShare from '@/Components/partials/NetworksShare';

	export default {
	    name: 'Stylekit',
        props : ['links'],
        data: () => ({
            placeholder : {
                video : 'https://placeimg.com/854/480/anima/grayscale', //16:9
                square : 'https://placeimg.com/640/640/animal/grayscale',
                rectangle : 'https://placeimg.com/844/616/animal/grayscale'
            }
        }),
	    components: {
            'v-button': Button,
            'v-image': Image,
            'v-artist-block': ArtistBlock,
            'v-video-block': VideoBlock,
            'v-image-block': ImageBlock,
            'v-image-box': ImageBox,
            'v-tweet-box': TweetBox,
            'v-grill': Grill,
            'v-title': Title,
            'v-book-end': BookEnd,
            'v-newsletter' : NewsLetter,
            'v-search' : Search,
            'v-networks-connect' : NetworksConnect,
            'v-networks-share' : NetworksShare,
        }
	}
</script>