<template>
  	<div class="page red">
    	<p>Store</p>
  	</div>
</template>

<script>

	// import Title from '@/Components/partials/Title';
	// import Paragraph from '@/Components/partials/Paragraph';
    // import Button from '@/Components/partials/Button';
    // import Legals from '@/Components/partials/Legals';
	
	export default {
	    name: 'Store',
        //props : ['links'],
	    components: {
            // 'v-legals': Legals,
        	// 'v-title': Title,
            // 'v-button': Button,
            // 'v-paragraph' : Paragraph,
        }
	}
</script>