<template>
  	<div class="page red">
    	<h1>Privacy Policy</h1>
  	</div>
</template>

<script>
	export default {
	    name: 'Privacy',
	    components: {
        }
	}
</script>