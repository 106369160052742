<template>

	<div class="image-block" v-bind:class="{ underline : underline }">

		<p class="title" v-if="title">{{title}}</p>

		<div class="thumb-wrap">

			<div class="thumb grey-dark">

        <a :href="href" target="_self" v-on:click.prevent="$root.$emit('click', href)" v-if="href"><img class="fill soft-light " :src="src" v-bind:class="{blackandwhite : blackandwhite}"></a>

        <img class="fill soft-light" v-bind:class="{blackandwhite : blackandwhite}" :src="src" v-else>

		   </div>

		</div>

    <h4 class="bolder" v-if="caption"><slot></slot></h4>
    <p class="date abs-bottom-left" v-if="date">{{date}}</p>
    <v-button class="abs-bottom-right">{{cta}}</v-button>

	</div>

</template>

<script>

	import Button from '@/Components/partials/Button';

	export default {
		name : 'ImageBlock',
		props : [ 'href', 'src', 'title', 'caption', 'cta', 'date', 'underline', 'blackandwhite' ],
		components : {
			'v-button': Button,
		}
	}
</script>