<template>

  	<div class="player">

        <audio :loop="innerLoop" :src="file" ref="audiofile" preload="auto"></audio>

        <ul>
            <li class="cover" v-on:click="onVisit">
                <div>
                    <img :src="cover" >
                    <!-- <i class="icon-factory" v-else></i> -->
                </div>
            </li>

            <li class="title double" v-on:click="onVisit">
                <div>
                    <p class="" v-if="title"><span class="bold upcase">{{title}}</span><br><span class="sm underline white">More Info</span></p>
                    <!-- <i class="icon-envelope" v-else></i> -->
                    <!-- <p class="underline sm white"> More Info</p> -->
                </div>
            </li>
            
            <li class="previous" v-on:click="onPrevious">
                <div>
                    <i class="icon-previous"></i>
                </div>
            </li>
            
            <li class="play" v-on:click="playing = !playing">
                <div>
                    <i class="icon-play" v-if="!playing"></i>
                    <i class="icon-pause" v-if="playing"></i>
                </div>
            </li>
            
            <!-- <li class="stop" v-on:click="stop">
                <div><i class="icon-factory"></i></div>
            </li> -->

            <!-- <li class="mute" v-on:click="mute">
                <div><i class="icon-factory"></i></div>
            </li> -->

            <!-- <li class="download" v-on:click="download">
                <div><i class="icon-factory"></i></div>
            </li> -->

            <!-- <li class="loop" v-on:click="innerLoop = !innerLoop">
                <div><i class="icon-factory"></i></div>
            </li> -->
            
            <li class="skip" v-on:click="onNext">
                <div>
                    <i class="icon-next"></i>
                </div>
            </li>
            
            <li class="open border half" v-on:click="onOpen">
                <div>
                    <i class="icon-dropdown" v-if="opened"></i>
                    <i class="icon-dropup" v-else></i>
                </div>
            </li>
            
        </ul>

    </div>

</template>

<script>

	export default {
        name: 'Player',
        //https://vuejsexamples.com/html5-basic-audio-player-with-vue-js/

        props: {

            file: {
                type: String,
                default: null
            },

            cover: {
                type: String,
                default: null
            },

            title: {
                type: String,
                default: null
            },


            autoPlay: {
                type: Boolean,
                default: false
            },
            loop: {
                type: Boolean,
                default: false
            }
        },
        
        data: () => ({
            audio: undefined,
            currentSeconds: 0,
            durationSeconds: 0,
            innerLoop: false,
            loaded: false,
            playing: false,
            previousVolume: 35,
            showVolume: false,
            volume: 100,
            opened: false,
        }),

        components:{

        },

        computed: {
            currentTime() {
                return convertTimeHHMMSS(this.currentSeconds);
            },
            durationTime() {
                return convertTimeHHMMSS(this.durationSeconds);
            },
            percentComplete() {
                return parseInt(this.currentSeconds / this.durationSeconds * 100);
            },
            muted() {
                return this.volume / 100 === 0;
            }
        },

        watch: {

            file(value){

                //console.log('audio file has changed, so do something', this.playing, value);

                if( !value ){
                    this.playing = false;
                    this.audio.currentTime = 0;
                }else{
                    //this.playing = true;
                }

            },

            playing(value) {
                if (value) { return this.audio.play(); }
                this.audio.pause();
            },
            volume(value) {
                this.showVolume = false;
                this.audio.volume = this.volume / 100;
            }
        },

        methods: {

            onVisit(evt){
                this.$emit( 'visit' );
            },

            onOpen(evt){
                this.opened = !this.opened;
                this.$emit( 'open' );
            },

            onPrevious(evt){
                this.$root.$emit( 'triggerAudio', 'previous' );
            },

            onNext(evt){
                this.$root.$emit( 'triggerAudio', 'next' );
            },

            load() {
                if (this.audio.readyState >= 2) {
                    this.loaded = true;
                    this.durationSeconds = parseInt(this.audio.duration);
                    return this.playing = this.autoPlay;
                }
                throw new Error('Failed to load sound file.');
            },
            mute() {
                if (this.muted) {
                    return this.volume = this.previousVolume;
                }

                this.previousVolume = this.volume;
                this.volume = 0;
            },
            stop() {
                this.playing = false;
                this.audio.currentTime = 0;
            },
            update(e) {
                this.currentSeconds = parseInt(this.audio.currentTime);
            }
        },

        created() {
            this.innerLoop = this.loop;
        },

        mounted() {
            this.audio = this.$el.querySelectorAll('audio')[0];
            this.audio.addEventListener('timeupdate', this.update);
            this.audio.addEventListener('loadeddata', this.load);
            this.audio.addEventListener('pause', () => { this.playing = false; });
            this.audio.addEventListener('play', () => { this.playing = true; });
        }

	}
</script>