<template>

	<div class="bookend yellow">

        <!-- <h1 class="firstline" v-bind:class="{outline : outline}"><slot></slot></h1>
        <h2 class="secondline" v-bind:class="{outline : outline}"  v-if="more">{{more}}</h2> -->

	    <div class="inner">

	    	<div class="rotated">
	    		<h1 class="white" v-bind:class="{outline : outline}"><slot></slot></h1>
	    		<h3 class="white" v-bind:class="{outline : outline}"  v-if="more">{{more}}</h3>

	    		<v-button
		    		v-if="cta"
	                class=""
	                :bold="true"
	                :label="label"
	                :href="href"
	                :icon="icon"
	            >{{cta}}</v-button>

	    	</div>

	    </div> 

    </div>

</template>

<script>

	import Button from '@/Components/partials/Button';

	export default {
		name : 'BookEnd',
		props : ['outline','more', 'label', 'href', 'cta', 'icon'],
		components: {
            'v-button': Button,
        },
	}
</script>