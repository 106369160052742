<template>

  	<div id="news">


        <article class="page twothirds">

            <div class="row fill-center">

                <div class="col twothirds">

                    <h2 class="bolder">News</h2>

                    <br>

                    <p>Keep up to date with everything about the Use Hearing Protection exhibition and all other news by signing up to Factory Communications.</p>

                    <br>

                    <h5 class="bold">Mailling List</h5>

                    <v-newsletter
                        :alternative="true"
                        :links="links"
                    ></v-newsletter>

                </div>

            </div>

        </article>
        


 <!--   <article class="page twothirds">

            <div class="row">

                <div class="col">

                    <h2 class="bold">News</h2>

                    <br>

                    <p>Keep up to date with everything about the Use Hearing Protection exhibition and all other news by signing up to Factory Communications.</p>

                </div>
           
            </div>

            <div class="row">

                <v-newsletter
                    :links="links"
                ></v-newsletter>

            </div>

        </article>
 -->


        <article class="page grid grey-lighter" v-if="articles">

            <div class="row space" v-for="articles in chunkedArticles">

                <div class="col" v-for="(item, key, index) in articles" :key="index" v-on:click.prevent="$root.$emit('click', '/news/' + item.slug )">
                 
                    <v-image-block
                        
                        :underline="true"
                        :blackandwhite="true"
                        :date="item.date"
                        :caption="true"
                        :cta="'Read More'"
                        :src="'/uploads/' + item.thumb"
                    >{{item.title | truncate(140) }}</v-image-block>

               </div>

               <div class="col" v-if="articles.length < chuck" v-for="n in (chuck - articles.length)">

                    <!-- <v-image-box
                        :href="'/exhibition'"
                        :name="'All'"
                        :label="'Show'"
                        :fill="true"
                        :direction="'left'"
                        v-if="n == (chuck - facs.length)"
                    ></v-image-box> -->

               </div>

            </div>

        </article>

    </div>

</template>

<script>

	import Grill from '@/Components/partials/Grill';
    import Title from '@/Components/partials/Title';
    import Button from '@/Components/partials/Button';
    import Image from '@/Components/partials/Image';
    import ArtistBlock from '@/Components/partials/ArtistBlock';
    import ImageBlock from '@/Components/partials/ImageBlock';
    import VideoBlock from '@/Components/partials/VideoBlock';
    import ImageBox from '@/Components/partials/ImageBox';
    import TweetBox from '@/Components/partials/TweetBox';
    import BookEnd from '@/Components/partials/BookEnd';
    import NewsLetter from '@/Components/partials/NewsLetter';
    import Search from '@/Components/partials/Search';

    import axios from 'axios';
    import VueAxios from 'vue-axios';

    let chunk = require('chunk'); //https://www.npmjs.com/package/chunk

	export default {
	    name: 'News',
        props:['links'],
        data: () => ({

            morebutton : false,
            articles :  false,
            chuck : 3,
            placeholder : {
                video : 'https://placeimg.com/854/480/anima/grayscale', //16:9
                square : 'https://placeimg.com/640/640/animal/grayscale',
                rectangle : 'https://placeimg.com/844/616/animal/grayscale'
            }
        }),
	    components: {
            'v-button': Button,
            'v-image': Image,
            'v-artist-block': ArtistBlock,
            'v-video-block': VideoBlock,
            'v-image-block': ImageBlock,
            'v-image-box': ImageBox,
            'v-tweet-box': TweetBox,
            'v-grill': Grill,
            'v-title': Title,
            'v-book-end': BookEnd,
            'v-newsletter' : NewsLetter,
            'v-search' : Search,
            // 'v-social-networks' : SocialNetworks,
        },

        computed: {
            chunkedArticles() {
                return chunk(this.articles, this.chuck);
            },
        },

        created() {
            this.getArticles();
        },

        methods: {

            getArticles:function( ){
                let self = this;
                axios.get('/data/articles.json').then(response => {
                    let articles = Array.from(response.data.articles);
                    const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    articles = articles.sort((a,b) => new Date( b.date.replace(pattern,'$3-$2-$1') ).getTime() - new Date( a.date.replace(pattern,'$3-$2-$1') ).getTime());
                    self.articles = articles;
                });
            }

        }

	}
</script>