<template>

  	<div id="exhibition">
    	
        <article class="page twothirds">

            <div class="row fill-center">

                <div class="col twothirds">

                    <h2 class="bolder tight">Exhibition Companion</h2>

                    <br>

                    <p><span class="italic">Use Hearing Protection: The early years of Factory Records</span> is a new exhibition, reinterpreting the display hosted at Chelsea Space, London in 2019. The development of new content for the exhibition was carried out by the Science and Industry Museum with the support of Jon Savage and Mat Bancroft, and Stuart Wheeley at Warner Music UK.</p>

                    <br>

                    <p>Select a FAC to learn more and listen to exclusive audio descriptions.</p>

                </div>

            </div>

        </article>

        <article class="page grey">

            <div class="row center">

                <div class="col twothirds">

                    <v-search>Search a FAC number to learn more</v-search>

                </div>

            </div>

        </article>


        <article class="page  grey-lighter" v-if="facs">

            <div class="row edge tabs">
                
                <div class="col" v-bind:class="{active : saved }" v-on:click.prevent="onClickExhibition">
                
                    <h4  class="bold center">ALL FAC's</h4>

                </div>

                <div class="col" v-bind:class="{active : !saved }" v-on:click.prevent="onClickSaved">
                
                    <h4  class="bold center">Favourites</h4>

                </div>

            </div>

        </article>


        <article class="page grid grey-lighter" v-if="facs">

            <div class="row" v-for="facs in chunkedFacs" v-if="!saved">

                <div class="col" v-for="(item, key, index) in facs" :key="index">

                    <v-image-box
                        :gradient="false"
                        :name="(item.slug.search('fact') > -1 ) ? 'FACT ' + item.id : 'FAC ' + item.id"
                        :cta="'View'"
                        :href="'/exhibition/'+item.slug"
                        :src="'/uploads/' + item.thumb"
                    ></v-image-box>

               </div>

               <div class="col" v-if="facs.length < chuck" v-for="n in (chuck - facs.length)">
                <!-- EMPTY -->
               </div>

            </div>

            <div class="row" v-for="facs in chunkedUserFacs" v-if="saved && userFacs.length > 0">

                <div class="col" v-for="(item, key, index) in facs" :key="index">
                 
                    <v-image-box
                        :gradient="false"
                        :name="(item.slug.search('fact') > -1 ) ? 'FACT ' + item.id : 'FAC ' + item.id"
                        :cta="'View'"
                        :href="'/exhibition/'+item.slug"
                        :src="'/uploads/' + item.thumb"
                    ></v-image-box>

               </div>

               <div class="col" v-if="facs.length < chuck" v-for="n in (chuck - facs.length)">
                <!-- EMPTY -->
               </div>

            </div>

            <div class="row center" v-if="saved && !userFacs">

                <div class="col twothirds">
                 
                    <h4 class="bold">You haven't favourited any FAC's yet. When you favourite a FAC it will be saved here for you to revisit.</h4>

               </div>

            </div>
 
        </article>

  	</div>

</template>

<script>

    import axios from 'axios';
    import VueAxios from 'vue-axios';

	import Grill from '@/Components/partials/Grill';
    import Title from '@/Components/partials/Title';
    import Button from '@/Components/partials/Button';
    import Image from '@/Components/partials/Image';
    import ArtistBlock from '@/Components/partials/ArtistBlock';
    import ImageBlock from '@/Components/partials/ImageBlock';
    import VideoBlock from '@/Components/partials/VideoBlock';
    import ImageBox from '@/Components/partials/ImageBox';
    import TweetBox from '@/Components/partials/TweetBox';
    import BookEnd from '@/Components/partials/BookEnd';
    import NewsLetter from '@/Components/partials/NewsLetter';
    import Search from '@/Components/partials/Search';

    let chunk = require('chunk'); //https://www.npmjs.com/package/chunk

	export default {
	    name: 'Exhibition',
        //props : ['links'],
        data: () => ({
            forceupdate: false,
            facs : false,
            userFacs : false,
            chuck : 6,
            saved : false,
            placeholder : {
                video : 'https://placeimg.com/854/480/anima/grayscale', //16:9
                square : 'https://placeimg.com/640/640/animal/grayscale',
                rectangle : 'https://placeimg.com/844/616/animal/grayscale'
            }
        }),
	    components: {
            'v-button': Button,
            'v-image': Image,
            'v-artist-block': ArtistBlock,
            'v-video-block': VideoBlock,
            'v-image-block': ImageBlock,
            'v-image-box': ImageBox,
            'v-tweet-box': TweetBox,
            'v-grill': Grill,
            'v-title': Title,
            'v-book-end': BookEnd,
            'v-newsletter' : NewsLetter,
            'v-search' : Search,
            // 'v-social-networks' : SocialNetworks,
        },

        created() {
            // console.log( typeof this.$route.path );
            this.saved = ( this.$route.path.search('favourites') >= 0 ) ? true : false;

            let self = this;
            this.getFacs( function( data ){
                self.facs = data;
            });
            //localStorage.removeItem('userFacs');
        },

        mounted() {

            if (localStorage.getItem('userFacs')) {
                try {
                    this.userFacs = JSON.parse(localStorage.getItem('userFacs'));
                } catch(e) {
                    localStorage.removeItem('userFacs');
                }
            }


            var self = this;
            this.$router.afterEach(function (to, from) {
                if( from.path.search('exhibition') > -1 ){
                    /* toggle saved if the route changes while on the exhibition */
                    self.saved = ( to.path.search('favourites') > -1 ) ? true : false;
                }

            });

            // this.$root.$emit( 'triggerAudio', 1 );

            //console.log( 'Exhibition MOUNTED', this.userFacs );

        },

        computed: {
            chunkedFacs() {
                return chunk(this.facs, this.chuck);
            },

            chunkedUserFacs() {
                return chunk(this.userFacs, this.chuck);
            }
        },

        methods: {

            onClickExhibition:function(){
                this.$router.push("/exhibition/" );
            },

            onClickSaved:function(){
                this.$router.push("/exhibition/favourites" );
            },

            getFacs:function( callback ){

                /*
                let data = require('/data/facs.json');
                return (data) ? data : false;
                */

                axios.get('/data/facs.json').then(response => {
                    let data = response.data.facs;
                    if ( data && callback ) callback(data);

                });

            },

            getUserFacs:function( ){

                if(this.facs){
                    return false;
                }

                let data = this.facs.filter(function(item) {
                    return item.slug == slug;
                });

                return (data) ? data : false;
            }

        }

	}
</script>