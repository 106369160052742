<template>
  <div class="page full error-not-found yellow">
    <img src="@/assets/images/error.svg">
    <h1 class="bolder">404</h1>
    <p class="bold center">Sorry, the page you are looking for cannot be found.</p>
      <a href="/">
        <h5 class="bold center pad underline">Go back</h5>
      </a>
  </div>
</template>

<script>
	import BookEnd from '@/Components/partials/BookEnd';

	export default {
	    name: 'PageNotFound',
	    components: {
            'v-book-end': BookEnd,
        }
	}
</script>