<template>

	<div class="tweet-box">

		<div class="thumb">
	       <img class="fill" :src="src">
	    </div>

	    <div class="tweet abs">
	       <p><slot></slot></p>
	       <span class="bold">hello world</span>
	    </div>

	    
	</div>

</template>

<script>

	export default {
		name : 'TweetBox',
		props : [  'src', 'href'  ],
		
	}
</script>