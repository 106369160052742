<template>

    <div class="newsletter alternative" v-if="enabled && alternative">

        <div class="row">

            <div class="col four">
            
                <div class="newsletter__success" v-if="success" v-on:click="resetForm">
                  <span>Success!</span>
                  <i class="newsletter__icon icon-success"></i>
                </div>
                
                <form id="newsletter" class="form-group" v-on:submit.prevent="submitForm" v-if="form">

                    <input class="bold" type="email" v-model="formdata.email" placeholder="Sign up for mailing list"/>

                    <input type="hidden" name="listid" v-model="formdata.listid" value="14078010">
                    <input type="hidden" id="Datasource" name="Datasource" v-model="formdata.Datasource" value="Factory_OfficialSite_MainSignup_Website" />

                    <label>
                      <button v-if="!loading" type="submit"><i class="icon-right"></i></button>
                      <div v-if="loading" class="newsletter__spinner"></div>
                    </label>
                    

                </form>

                <div class="newsletter__error" v-if="error" v-on:click="resetForm">
                  <i class="newsletter__icon icon-error"></i>
                  <span>Something went wrong, please try again</span>
                </div>

            </div>

            <div class="col one"></div>

            <div class="col six terms">

                <!-- <h5 class="bold">Terms</h5> -->

                <p class="sm" v-if="terms">By submitting my information, I agree to receive personalized updates and marketing messages about Factory Records based on my information, interests, activities, website visits and device data and in accordance with the <a :href="links.privacy" class="underline" target="_blank">Privacy Policy</a>. I understand that I can opt-out at any time by emailing <a target="_blank" class="underline" href="mailto:privacypolicy@wmg.com">privacypolicy@wmg.com</a>.</p>

            </div>

        </div>

    </div>

	<div class="newsletter" v-else="enabled">


        <div class="row">

            <div class="col twothirds">
            
                <div class="newsletter__success" v-if="success" v-on:click="resetForm">
                  <span>Success!</span>
                  <i class="newsletter__icon icon-success"></i>
                </div>

                <form id="newsletter" class="form-group" v-on:submit.prevent="submitForm" v-if="form">

                    <input type="email" v-model="formdata.email" placeholder="Sign up for mailing list"/>

                    <input type="hidden" name="listid" v-model="formdata.listid" value="14078010">
                    <input type="hidden" id="Datasource" name="Datasource" v-model="formdata.Datasource" value="Factory_OfficialSite_MainSignup_Website" />

                    <label>
                      <button v-if="!loading" type="submit"><i class="icon-right"></i></button>
                      <div v-if="loading" class="newsletter__spinner"></div>
                    </label>



                </form>

                <div class="newsletter__error" v-if="error" v-on:click="resetForm">
                  <i class="newsletter__icon icon-error"></i>
                  <span>Something went wrong, please try again</span>
                </div>

            </div>

        </div>

        <div class="row">

            <div class="col five terms">

                <h5 class="bold">Terms</h5>

                <p class="sm" v-if="terms">By submitting my information, I agree to receive personalized updates and marketing messages about Factory Records based on my information, interests, activities, website visits and device data and in accordance with the <a :href="links.privacy" class="underline" target="_blank">Privacy Policy</a>. I understand that I can opt-out at any time by emailing <a target="_blank" class="underline" href="mailto:privacypolicy@wmg.com">privacypolicy@wmg.com</a>.</p>

            </div>

            <div class="col one"></div>
        </div>

    </div>

</template>

<script>

    import axios from 'axios';
    import VueAxios from 'vue-axios';

	export default {
		name : 'NewsLetter',
        props : ['links', 'alternative'],
        data() {
            return {
                terms : true,
                enabled : true,
                form : true,
                success : false,
                error: false,
                loading: false,
                url : "https://signup.wmg.com/register",
                formdata: {
                    email: '',
                    listid: '14078010',
                    Datasource : 'Factory_OfficialSite_MainSignup_Website',
                }
            }
        },

        methods: {

            toggleTerms:function(){
                this.terms = !this.terms;
            },

            resetForm:function(){
                this.form = !this.form;
                this.success = false;
                this.error = false;
                this.loading = false;
            },


            submitForm:function(){
                //console.log(  this.formdata );

                if ( this.formdata.email && this.formdata.listid && this.formdata.Datasource ){
                    this.loading = true;

                    let url = this.url + "?email="+this.formdata.email+"&listid="+this.formdata.listid+"&Datasource=" + this.formdata.Datasource;

                    let _this = this;

                    axios.get( url )
                    .then(function (response) {
                        //console.log(response);
                        _this.email = '';
                        _this.form = false;
                        _this.success = true;
                        _this.loading = false;
                        window._satellite.track("email sign-up");
                    })
                    .catch(function (error) {
                        //console.log(error);
                        //_this.form = false;
                        _this.error = true;
                        _this.loading = false;
                        _this.email = '';
                        setTimeout( () => {
                          _this.error = false;
                        }, 3000);
                    });

                }

            }
        }
    }
</script>