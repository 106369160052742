import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueTouch from 'vue-touch';

/* LAZY LOAD */
import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
    // lazyComponent: true,
    preLoad: 1.3,
    //error: 'load-error.png' ,
    loading: 'load.svg',
    attempt: 1,
    listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'touchmove'],
});

/* truncate */
Vue.filter('truncate', function (value, limit) {
    if (value.length > limit) {
        value = value.substring(0, (limit - 3)) + '...';
    }

    return value
})

/* YOURUBE */
import VueYoutube from 'vue-youtube';
Vue.use(VueYoutube);

/* CONFIG */
Vue.config.productionTip = false;

const app = new Vue({
	el: '#app',
	router,
	components: { App },
	template: '<App/>',
    render : h => h(App)
});