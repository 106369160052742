<template>

	<div class="row playlist-block">
		
		<div class="col playlist-wrap">
			<spotify
				class="spotify"
				ref="spotify"
				:src="playlist.url"
			></spotify>
		</div>

	</div>

</template>

<script>

	import Button from '@/Components/partials/Button';
	import Spotify from '@/Components/partials/Spotify';

	export default {
		name : 'PlaylistBlock',
		props : [ 'playlist' ],
		components : {
			'v-button': Button,
			'spotify' : Spotify,
		}
	}
</script>