<template>
  	<nav class="main">
  		<ul>
            
  			<li v-bind:class="{active : channel.search(links.exhibition) >= 0 }">
                <a :href="links.exhibition" target="_self" v-on:click.prevent="$root.$emit('click', links.exhibition)">
                    <h5 class="bold">Exhibition Companion</h5>
                </a>
            </li>

            <li v-bind:class="{active : channel.search(links.playlists) >= 0 }">
                <a :href="links.playlists" target="_self" v-on:click.prevent="$root.$emit('click', links.playlists)">
                    <h5 class="bold">Playlists</h5>
                </a>
            </li>

            <li v-bind:class="{active : channel.search(links.news) >= 0 }">
                <a :href="links.news" target="_self" v-on:click.prevent="$root.$emit('click', links.news)">
                    <h5 class="bold">News</h5>
                </a>
            </li>

            <li>
                <a :href="links.store" data-track="enter-store" target="_blank">
                    <h5 class="bold">Store</h5>
                </a>
            </li>

            <li>
                <a :href="links.book" data-track="book-tickets" target="_blank">
                    <h5 class="bold border">Book Tickets</h5>
                </a>
            </li>


  		</ul>
    </nav>
</template>

<script>
    
	export default {
	    name: 'Navigation',
		props : ['links', 'channel'],

        created() {
        
            // console.log( this.channel );

        },
	}
</script>