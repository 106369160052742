<template>

  	<header>

        <Navigation
            :channel="channel"
            :links="links"/>

        <div class="logo"><a :href="links.home" v-on:click.prevent="$root.$emit('click', links.home)"  ><div class="img"></div></a></div>

        <div class="hamburger" :class="this.$route.name === 'Menu' && 'hamburger--active'" v-on:click="toggleMenu">
            <div></div>
            <div></div>
            <div></div>
        </div>

    </header>

</template>

<script>

    import Navigation from '@/components/Navigation';

	export default {
        name: 'Header',
        props : ['links', 'channel'],
        data: () => ({
            menu :  false,
            saving : false,
            userFacs : false,
        }),
        components:{
            Navigation,
        },

        methods:{
            toggleMenu : function(){

                this.menu = !this.menu;

                if( this.menu  ){
                    this.$router.push("/menu");
                }else{
                    this.$router.go(-1);
                }

            }
        }

	}
</script>