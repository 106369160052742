<template>
    
    <div class="app" ref="app">

      <Header
        ref="header"
        :channel="channel"
        :links="links"/>

        <div class="wrapper" ref="wrapper">

            <Pages
                v-on:updated="onScreenUpdate"
                :channel="channel"
                :share="share"
                :links="links"/>

            <Footer
                v-if="$route.name != 'Menu'"
                :links="links"/>

        </div>

        <Companion />

    </div>

</template>

<style lang="scss">
    @import "./assets/scss/style";
</style>

<script>

    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import Pages from '@/components/Pages';
    import Companion from '@/components/Companion';
    
    export default {

        name: 'App', 

        components: {
            Footer,
            Header,
            Pages,
            Companion
        },

        data: () => ({

            show : {
                menu : false
            },

            links : {

                home: '/',
                exhibition: '/exhibition',
                // live: '/live',
                playlists: '/playlists',
                news: '/news',
                store: 'https://shop.thisisdig.com/uk/factory.html',
                // book: 'https://my.scienceandindustrymuseum.org.uk/events?view=calendar&kid=415&StartDate=01-06-21',

                book: 'https://www.scienceandindustrymuseum.org.uk/whats-on/use-hearing-protection',

                instagram: 'https://rhinouk.lnk.to/FactoryInstagram',
                youtube: 'https://rhinouk.lnk.to/FactoryYoutube',
                twitter: 'https://rhinouk.lnk.to/FactoryTwitter',
                facebook: 'https://rhinouk.lnk.to/FactoryFacebook',

                // terms: '/terms-and-conditions',
                // cookies: '/privacy-policy',
                // privacy: '/privacy-policy',
                
                terms: 'https://wminewmedia.com/terms-of-use/',
                cookies: 'https://www.wminewmedia.com/cookies-policy/en/',
                privacy: 'https://wminewmedia.com/privacy/',
                
            },

            share : {

                url : 'https://usehearingprotection.com',
                copy : 'Factory Communications. Celebrating the life and legacy of Factory Records. 1978 - 1992.',
                via : 'via',
                hashtag: "factory_comms",
            },

            channel : false
        }),

        watch:{
            $route (to, from){
                // console.log(to,from, window.reloadOTBanner)
                if( window.reloadOTBanner ) window.reloadOTBanner;
            },
        },

        mounted() {

            this.$root.$on('click', ( link ) => {

                if ( link == this.$router.currentRoute.path ) return;
                if( link && link.search('http') === -1 ){
                    this.$router.push(link);
                }else{
                    window.open(link, '_blank')
                }
            });


            this.$root.$on('scrollTop', ( link ) => {
                //console.log( 'scroll to the top', this.$refs, this.$refs.wrapper  );
                this.$nextTick(() => {
                    this.$refs.wrapper.scrollTop = 0;
                });
            });


            this.adobeTrack( true );

            var self = this;
            this.$router.afterEach(function (to, from) {
                self.$root.$emit('scrollTop'); // scroll back to top after each route change
                self.adobeTrack( false ); /* Need to do some Adobe tracking on page change too */
            });

            //window.addEventListener('resize', this.handleResize)
            //this.handleResize();
           
            //this.stickyheader();
        },

        created() {
            console.log('%c Modern English 🙉', 'color: white; background: black; padding: 2px 4px;');
            this.channel = this.$route.path;
            // Listen to the app changing page
        },

        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },

        methods: {

            stickyheader(){
                const header = this.$refs.header.$el;
                const sticky = header.offsetTop;
                this.$refs.wrapper.addEventListener('scroll', ()=>{
                    this.$refs.header.$el.style.top = this.$refs.wrapper.scrollTop + 'px';
                    // console.log( this.$refs.wrapper.scrollTop,  this.$refs.header.$el.style.top );
                    if (this.$refs.wrapper.scrollTop > sticky) {
                       // this.$refs.header.$el.classList.add("sticky");
                    } else {
                       //s this.$refs.header.$el.classList.remove("sticky");
                    }
                });

            },

            handleResize() {

                console.log( this.$refs.app );

                //TO DO: fix the 100vh issue for mobile so the player tray can be the size of the screen
                const height = Math.max(this.$refs.app.offsetHeight, window.innerHeight || 0);
                this.$refs.app.style['height'] = height + 'px';

            },


            adobeTrack( addScript ){

                if ( !this.$route.meta.pageName ){
                    return false;
                }

                let pageName = this.$route.meta.pageName;
                pageName = ( this.$route.params.slug && pageName.search( '{SLUG}' ) > -1 ) ? pageName.replace(/{SLUG}/g, this.$route.params.slug ) : pageName ;
                let primaryCategory = this.$route.meta.primaryCategory;
                let pageType = this.$route.meta.pageType;

                //console.log( 'adobeTrack ', pageName, primaryCategory, pageType );
                
                if ( addScript ){

                    /* Add the script now we know what page we are on and digitalData object can be created */

                    let adobeScript = document.createElement('script')
                    adobeScript.setAttribute('src', '//assets.adobedtm.com/31543e7db99435a92d6f4a2cf97c808672ed7dd0/satelliteLib-b6e3529c31d92a9252c30c1714b6b9342304145b.js')
                    document.head.appendChild(adobeScript); 

                    setTimeout(function(){

                        let code = "_satellite.pageBottom();";
                        let script = document.createElement("script");
                        script.setAttribute("type", "text/javascript");
                        script.appendChild(document.createTextNode(code));
                        document.body.appendChild(script);

                    }, 1000 );

                }

                window.digitalData = {
                    settings:{
                        reportSuites:"wmg,wmggbglobal"
                    },
                    page:{
                        pageInfo:{
                        pageName:pageName,
                        server:"Factory Records:Site",
                        platform:"Static HTML"
                    },
                    category:{
                        primaryCategory:primaryCategory,
                        pageType:pageType
                    }
                    },
                    content:{
                        artist:"Factory Records",
                        label:"Warner Music International",
                        sublabel:"Rhino UK"
                    },
                    user:{
                        profile:{
                            profileID:""
                        }
                    }
                }

                if( window._satellite ){
                    window._satellite.track("page view");
                }
            },

            onScreenUpdate( channel ){
                this.channel = channel;
            },

            onToggleMenu(e){
                this.show.menu = !this.show.menu;
            },

            onCloseMenu(e){
                this.show.menu = false;
            },

           /*onButtonClick (e){

                var url, params;
                url = false;

                switch(network){

                    // case 'twitter':

                    //     url = "http://twitter.com/share?";
                        
                    //     params = {
                    //         url: "https://share.domain.com", 
                    //         text: "zzz",
                    //         via: "zzz",
                    //         hashtags: "zzzz"
                    //     }

                    // break;


                    // case 'facebook':

                    //     url = 'https://www.facebook.com/sharer/sharer.php?';

                    //     params = {
                    //         u: "https://share.domain.com", 
                    //     }

                    // break;

                    default:
                        //TO DO
                    break;
                }

                //console.log('onShare', url );

               if(url){
                    for(var prop in params) url += '&' + prop + '=' + encodeURIComponent(params[prop]);
                    window.open(url, '', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
               }
               
            }*/
        }

    }

</script>