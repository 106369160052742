<template>
	
	<button class="btn" v-on:click="onClick">
	 	<p class="label regular rotate-up-back" v-if="label">{{label}}</p>
    <p class="cta" v-bind:class="{bolder : bold}"><slot></slot></p>
    <i :class="iconbefore" v-if="iconbefore"></i>
    <i :class="icon"></i>
   </button>

</template>

<script>
	export default {
		name : 'Button',
		props : [ 'href' ],
		props :{
			href :  {
      			default: false
			},
			label :  {
      			default: false
			},
			bold : {
				default: false
			},
			iconbefore :  {
      			default: false
			},
			icon :  {
      			default: 'icon-right'
			}
		},

		methods: {

			onClick : function(){
				if( this.href && this.href.search('http') == -1 ){
					this.$router.push(this.href);
				}else if (this.href){
					window.open( this.href, '_blank' );
				}
			}
		}
	} 
</script>