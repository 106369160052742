<template>

	<div class="search">

		<div class="row">

			<div class="col">

				<h4 class="center"><slot></slot></h4>

    	</div>

			<div class="col search-wrap">
            
        <form id="newsletter" class="form-group center" v-on:submit.prevent="onSubmit">

          <input v-model.number="search" v-on:input="onInput" v-on:change="onChange" type="number" maxlength="2" min="1" max="50" 
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" placeholder="00" class="bold"/>

          <label><button type="submit"><i class="icon-right"></i></button></label>

        </form>

    	</div>

   </div>

  </div>

</template>

<script>

	import Button from '@/Components/partials/Button';



	/*

	TODO:

	Form validation
	https://travishorn.com/form-validation-with-vue-js-4d2e7ba8d2fc

	*/

	export default {
		name : 'Search', // Fac Search
		data: () => ({
		    search: null,
		    facts : [10,14,16,24,25,30,40,42,44,45,50],
		}),
		components: {
            'v-button' : Button,
        },
		methods: {


			onInput:function( evt ){

				//console.log( 'onInput', this.search );
				// const value = evt.target.value
				// console.log(value, this.search)
				// if (String(value).length > 2) {
				// 	console.log( value )
				// 	this.search = value;
				// }
				// const value = evt.target.value
				// this.search = value.replace(/^[0-9]{3}$/g, '');
				//this.$forceUpdate();
			},

			onChange:function( ){
				//console.log( 'onChange', this.search );
			},

			onSubmit:function( ){
        console.log(this.search);
				if ( this.search ){
			    	let slug = ( this.facts.includes(this.search) ) ? "fact" + this.search : "fac" + this.search;
					if ( this.$router.currentRoute.path.search('/fac') > -1 ){
						// this.$router.go(); //-can't use this
						//this.$root.$emit('forceFacUpdate', slug);
            this.$router.push("/exhibition/" + slug );
					}else{
						this.$router.push("/exhibition/" + slug );
					}
				}
		  }
		}
	}
</script>