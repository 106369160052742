<template>

    <div id="home">

      	<article class="page full">

            <div class="row hero fill-center">
  
                <div class="col half">
                    
                    <div class="row keyart">
                        <img class="fill-width pad" src="@/assets/images/use-hearing-protection-keyart.svg">
                    </div>

                    <div class="row center keyart-dates">

                        <div class="col third">
                            <img class="efill-width" src="@/assets/images/mosi-logo.svg">
                        </div>

                        <div class="col third">
                          <h4 class="bold tight right">19 Jun 21 <br>– 03 Jan 22</h4>
                        </div>

                    </div>

                </div>

                <div class="hero__arrow">
                  <i class="icon-dropdown"></i>
                </div>

            </div>

        </article>

        <article class="page full-w-header"> 

            <div class="row fill-center">

                <div class="col twothirds">

                    <h2 class="bolder">Celebrate Manchester's place at the heart of Britain's music and creative industries with this special exhibition dedicated to one of the city's most influential record labels.</h2>

                    <br>

                    <p>Discover the story of Factory Records' formative years from 1978 to 1982, and how their innovative work in music, technology and design gave Manchester an authentic voice and distinctive identity.</p>

                    <br>

                    <p>See the first 50 artefacts from the official Factory catalogue alongside previously unseen items from the Factory archives, as well as objects loaned from the estates of both Tony Wilson and Rob Gretton, the former manager of Joy Division and New Order.</p>

                    <br>

                    <div class="row">

                        <div class="col third pointer">

                            <a :href="links.book" data-track="book-tickets" target="_blank">
                                <h5 class="bold border center pad">Book Tickets</h5>
                            </a> 

                        </div>

                        <div class="col twelvth"></div>

                        <div class="col third center"> 

                            <a :href="links.exhibition" target="_self" v-on:click.prevent="$root.$emit('click', links.exhibition)">
                                <h5 class="bold center pad underline">Go to the exhibition companion</h5>
                            </a>

                        </div>

                    </div>


                </div>

            </div>

        </article>

        <article class="page full grey-lighter">

            <div class="row fill-center">

                <div class="col twothirds">

                    <div class="row">

                        <div class="col">

                            <h2 class="bolder tight">Use Hearing Protection limited edition box set</h2>

                            <br>

                            <p><span class="bold">Designed by Factory director Peter Saville, and only 4,000 planned for production,</span> ‘Use Hearing Protection’ is a limited edition box set including the first 10 FAC items, with a 52-page exhibition style book, plus a bonus 12'' single and 2CDs of unheard Joy Division interviews.</p>

                        </div>

                        <div class="col">

                            <img class="pad" :src="require('@/assets/images/uhp-boxset.png')">

                            <div class="row center">

                                <div class="col half">

                                    <a :href="links.store" data-track="buy-now" target="_blank">
                                        <h5 class="bold border center pad">Buy Now</h5>
                                    </a> 
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </article>

        <article class="page twothirds">

            <div class="row fill-center">

                <div class="col twothirds">

                    <h2 class="bolder tight">Hear the sounds that defined a city</h2>

                    <br>

                    <p>Immerse yourself in Factory Records’ early music in the dedicated Gig Room and get hands on with the technologies that made the label’s sound so unique, including a mixing desk and synthesiser. To coincide with the exhibition we have compiled a series of playlists that epitomise the epochal work of Factory Records.</p>

                    <br>

                    <div class="row">

                        <div class="col third center"> 

                            <a :href="links.playlists" target="_self" v-on:click.prevent="$root.$emit('click', links.playlists)">
                                <h5 class="bold center pad underline">Explore the playlists</h5>
                            </a>

                        </div>

                    </div>


                </div>

            </div>

        </article>

    </div>

</template>

<script>

	import Grill from '@/Components/partials/Grill';
    import Title from '@/Components/partials/Title';
    import Button from '@/Components/partials/Button';
    import Image from '@/Components/partials/Image';
    import ArtistBlock from '@/Components/partials/ArtistBlock';
    import ImageBlock from '@/Components/partials/ImageBlock';
    import VideoBlock from '@/Components/partials/VideoBlock';
    import ImageBox from '@/Components/partials/ImageBox';
    import TweetBox from '@/Components/partials/TweetBox';
    import BookEnd from '@/Components/partials/BookEnd';
    import NewsLetter from '@/Components/partials/NewsLetter';
    import Search from '@/Components/partials/Search';
	
	export default {
	    name: 'Home',
        props : ['links'],
        data: () => ({

            video : false, //LS7CiL8MNb0

            placeholder : {
                video : 'https://placeimg.com/854/480/anima/grayscale', //16:9
                square : 'https://placeimg.com/640/640/animal/grayscale',
                rectangle : 'https://placeimg.com/844/616/animal/grayscale'
            }
        }),
        components: {
            'v-button': Button,
            'v-image': Image,
            'v-artist-block': ArtistBlock,
            'v-video-block': VideoBlock,
            'v-image-block': ImageBlock,
            'v-image-box': ImageBox,
            'v-tweet-box': TweetBox,
            'v-grill': Grill,
            'v-title': Title,
            'v-book-end': BookEnd,
            'v-newsletter' : NewsLetter,
            'v-search' : Search,
            // 'v-social-networks' : SocialNetworks,
        }
	}
</script>