<template>
  	<div class="page red">
    	<h1>Terms and Conditions</h1>
  	</div>
</template>

<script>

	export default {
	    name: 'Terms',
	    components: {
        }
	}
</script>