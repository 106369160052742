<template>

  	<article class="page full menu black" v-bind:class="{opened : newsletter}">

        <nav class="right vertical bottom">

            <a :href="links.exhibition" target="_self"  v-on:click.prevent="onClickMenu(links.exhibition)">
                <h2 class="white right bold" v-bind:class="{bold : prevRoute && prevRoute.path.search(links.exhibition) >= 0 }">Exhibition<br>Companion</h2>
            </a>

            <a :href="links.playlists"  target="_self"  v-on:click.prevent="onClickMenu(links.playlists)">
                <h2 class="white right bold" v-bind:class="{bold : prevRoute && prevRoute.path.search(links.playlists) >= 0 }">Playlists</h2>
            </a>

            <a :href="links.news" target="_self" v-on:click.prevent="onClickMenu(links.news)">
                <h2 class="white right bold" v-bind:class="{bold : prevRoute && prevRoute.path.search(links.news) >= 0 }">News</h2>
            </a>

       <!--      <a :href="links.home" target="_self" v-on:click.prevent="onClickMenu(links.home)">
                <h2 class="white right" v-bind:class="{bold : prevRoute && prevRoute.path == links.home }">Home</h2>
            </a> -->
           
            <a :href="links.store" target="_blank" data-track="enter-store">
                <h2 class="white right bold" v-bind:class="{bold : prevRoute && prevRoute.path.search(links.store) >= 0 }">Store</h2>
            </a>

           <!--  <a :href="links.book" data-track="book-tickets" target="_blank">
                <h5 class="bold border">Book Tickets</h5>
            </a> -->

            <a :href="links.book" target="_blank" data-track="enter-store">
                <h4 class="yellow right bold" v-bind:class="{bold : prevRoute && prevRoute.path.search(links.store) >= 0 }">Book Tickets</h4>
            </a>

        </nav>

    </article>              

</template>

<script>

    import NetworksConnect from '@/Components/partials/NetworksConnect';
    import NewsLetter from '@/Components/partials/NewsLetter';
    
	export default {
	    name: 'Menu',
        props : ['links', 'channel'],
	    components: {
            'v-newsletter' : NewsLetter,
            'v-networks-connect' : NetworksConnect,
        },

        data: () => ({
            prevRoute: false,
            newsletter:false,
        }),

        beforeRouteEnter(to, from, next) {
            next(vm => {
                console.log( from );
                vm.prevRoute = from;
            })
        },

        methods:{

            onClickMenu:function( slug ){
                this.$root.$emit('closeMenu', slug );
                this.$root.$emit('click', slug );
            },

            toggleNewsletter : function(){
                this.newsletter = !this.newsletter;
            }
        }

	}
</script>