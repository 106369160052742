<template>

  	<div id="companion" v-bind:class="{hide : !player.open}">

        <div class="facsMenu" v-if="menu">

            <div class="row">
                
                <div class="col">
                    
                    <p class="white tight">Select an audio track</p>

                    <br>

                </div>

            </div>

            <div class="row space sm-three" v-for="facs in chunkedFacs">

                <div class="col fac"  v-for="(item, key, index) in facs" :key="index">



                    <div v-bind:class="{active : item.id == player.id}" v-on:click="onSelectFac(item.id)">
                        <p class="bold center white">{{item.id}}</p>
                    </div>
                </div>

               <div class="col fac" v-if="facs.length < chuck" v-for="n in (chuck - facs.length)">
               </div>

            </div>

        </div>

        <Player
            v-if="player.open"
            v-on:open="onOpenMenu"
            v-on:visit="onVisitFac"
            :autoPlay="player.autoPlay"
            :cover="player.cover"
            :title="player.title"
            :file="player.file"/>

    </div>

</template>

<script>

    import axios from 'axios';
    import VueAxios from 'vue-axios';
    import Player from '@/components/partials/Player';
    var chunk = require('chunk'); //https://www.npmjs.com/package/chunk

	export default {

        name: 'Companion',

        props: {

        },
        
        data: () => ({

            fac : false,
            facs : false,
            menu : false,
            chuck : 5,

            player : {
                id : false,
                open : false,
                file : false,
                cover : false,
                title : false,
                autoPlay : true
            }
        }),

        computed:{
            isOpen(){
                return this.player.open;
            },
            chunkedFacs() {
                return chunk(this.facs, this.chuck);
            },
        },

        watch:{

            $route( to, from ){
                if( to.path.includes('exhibition') && !this.player.open ) {
                    this.$root.$emit( 'triggerAudio', 1, false );
                }else if( to.path.includes('menu') && this.player.open ){
                    this.player.open = false;
                }
            },

            isOpen( val ){
                
                var body = document.body;

                if( val ){
                    body.classList.add("companion");
                }else{
                    body.classList.remove("companion");
                }
                
            },
            
        },

        components:{
            Player
        },

        created() {

            let self = this;
            this.getFacs( function( data ){
                self.facs = data;

            });
        },

        mounted() {


            this.$root.$on('triggerAudio', ( id, play = true ) => {


                this.player.autoPlay = play;

                if ( id == 'previous' ){
                    id = (this.player.id > 1 ) ? this.player.id - 1 : 50;
                }

                if ( id == 'next' ){
                    id = (this.player.id < 50 ) ? this.player.id + 1 : 1;
                }

                let self = this;

                this.getFac( id, function( data ){

                    self.fac = data;
                    self.updateAudio( id );

                    if( !self.fac && !self.fac.audio){
                        return false;
                    }

                });

            });


            if( this.$route.path.includes('exhibition') && !this.player.open ) {
                this.$root.$emit( 'triggerAudio', 1, false );
            }

        },

        methods: {

            updateAudio( id ){

                this.player.id = id;

                if( this.player.open ){

                    this.player.file = null;

                    this.$nextTick(() => {
                        this.player.title = this.fac.slug.replace(/[^0-9](?=[0-9])/g, '$& ');
                        // this.player.cover = require( '@/assets/facs/' + this.fac.thumb);
                        this.player.cover = '/uploads/' + this.fac.thumb;
                        this.player.file = ( this.fac.audio.search('http') > -1 ) ? this.fac.audio : require( '@/assets/audio/' + this.fac.audio);
                    });

                }else{

                    this.player.title = this.fac.slug.replace(/[^0-9](?=[0-9])/g, '$& ');
                    // this.player.cover = require( '@/assets/facs/' + this.fac.thumb);
                    this.player.cover = '/uploads/' + this.fac.thumb;
                    this.player.file = ( this.fac.audio.search('http') > -1 ) ? this.fac.audio : require( '@/assets/audio/' + this.fac.audio);
                    this.player.open = true;

                }

            },

            getFacs:function( callback ){

                /*
                let data = require('@/assets/data/facs.json');
                return (data) ? data : false;
                */

                axios.get('/data/facs.json').then(response => {
                    let data = response.data.facs;
                    //console.log( data )
                    if ( data && callback ) callback(data);
                });
            },

            getFac:function( id, callback ){


                /*
                let data = require('@/assets/data/facs.json');

                data = data.filter(function(item) {
                    return item.id == id;
                });

                return ( data[0] ) ? data[0] : false;
                */

                axios.get('/data/facs.json').then(response => {

                    let data = response.data.facs;

                    data = data.filter(function(item) {
                        return item.id == id;
                    });

                    if ( data[0] && callback ) callback(data[0]);

                });

            },

            onOpenMenu:function(){
                this.menu = !this.menu;
            },

            onVisitFac:function( value ){
                let slug = ( value ) ? value : this.fac.slug;
                this.$router.push("/exhibition/" + slug );
            },

            onSelectFac:function( id ){
                this.menu = !this.menu;
                this.$root.$emit( 'triggerAudio', id );
            }
        },

	}
</script>