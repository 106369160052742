<template>

	<div class="icons" v-bind:class="{right : right}">

        <ul>

            <li class="facebook"><a :href="links.facebook" data-track="facebook" target="_blank"><i class="icon-facebook"></i></a></li>

            <li class="twitter"><a :href="links.twitter" data-track="twitter" target="_blank"><i class="icon-twitter"></i></a></li>

            <li class="youtube"><a :href="links.youtube" data-track="youtube" target="_blank"><i class="icon-youtube"></i></a></li>

            <li class="instagram"><a :href="links.instagram" data-track="instagram" target="_blank"><i class="icon-instagram"></i></a></li>

        </ul>

    </div>

</template>

<script>
	export default {
		name : 'NetworksConnect',
		props : [  'links', 'right' ],
	}
</script>