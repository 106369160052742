<template>

	<div class="artist-block">

		<div class="thumb">

			<p class="label bold"><span><slot></slot></span></p>

	        <v-image
                :src="src"
            ></v-image>

	    </div>

	</div>

</template>

<script>

	import Image from '@/Components/partials/Image';

	export default {
		name : 'ArtistBlock',
		props : [  'src', 'href', 'cta' ],
		data: () => ({
            active : false
        }),
		components: {
            'v-image': Image
        }
	}
</script>