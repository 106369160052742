<template>

	<div class="title">
        <h1 class="outline"><slot></slot></h1>
    </div>

</template>

<script>
	export default {
		name : 'Title',
		props : [  ]
	}
</script>