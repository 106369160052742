<template>

	<div class="video-block" @click="playVideo">

		<p class="title" v-if="title">{{title}}</p>

		<div class="video-wrap">

			<div class="video black" v-bind:class="{ playing : playing, poster : poster }">


				<div class="youtube-holder sixteen-nine"" v-if="youtube">
					<youtube
						class="youtube"
						ref="youtube"
						:video-id="youtube"
						:player-vars="playerVars"
						@ready="onReady"
						@ended="onEnded"
						@playing="onPlaying"
						@paused="onPaused"
					></youtube>
				</div>

				<div class="img" v-bind:style="{ backgroundImage: 'url(' + poster + ')' }" v-if="poster"></div>

		        <!-- <img class="fill soft-light" :src="src"> -->

		        <v-button
		        	class="white abs-top-left"
		        	:icon="'icon-play'"
		        	:bold="true"
	            >{{cta}}</v-button>

	            <v-button
		        	class="grey abs-top-right rotate-down"
		        	:icon="'icon-play'"
	            >{{cta}}</v-button>

	            <v-button
		        	class="grey abs-bottom-left rotate-up"
		        	:icon="'icon-play'"
	            >{{cta}}</v-button>

	            <v-button
		        	class="white abs-bottom-right"
		        	:icon="'icon-play'"
	            ></v-button>

		    </div>

		</div>

	</div>

</template>

<script>

	import Button from '@/Components/partials/Button';

	export default {
		name : 'VideoBlock',
		props : [ 'poster', 'title', 'cta', 'youtube' ],
		components : {
			'v-button': Button,
		},
		data() {
		    return {
				playerVars: {
					autoplay: 0,
					disablekb: 1,
					modestbranding: 1,
					suggestedQuality: 'hd720',// 'default' no longer works, but others do http://goo.gl/Ua6gEU
				},
		    	playing: false,
		    }
		},
		methods: {

		    playVideo( e ) {
		    	this.player.playVideo();
		    },

		    onReady( e ) {

		    },

		    onEnded( e ) {
		      this.playing = false;
		    },

		    onPlaying( e ) {
		      this.playing = true;
		    },

		    onPaused( e ) {
		      this.playing = false;
		    },

		},

		computed: {
		    player() {
		    	return this.$refs.youtube.player;
		    }
	  	}
	}
</script>