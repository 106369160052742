<template>

	<div>

		<p>Please enable <a @click.prevent="showSettings" style="cursor:pointer">functional cookies</a><br> to view the playlist</p>

		<iframe  v-if="OptanonWrapper" class="optanon-category-C0003 embed__iframe"  :data-src="src" data-track="spotify-emb" width="100%" height="100%" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>

		<iframe  v-if="OptanonWrapper === false" class="embed__iframe"  :src="src" data-track="spotify-emb" width="100%" height="100%" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>

	</div>

</template>

<script>

	export default {
		name : 'Spotify',
		props : [ 'src' ],
		data: function () {
        return {
            OptanonWrapper : null, // onetrust is enabled and ready (null = waiting, false = no show, function = ready)
        }
    },
		mounted(){
      var stop = Date.now() + 2000; // we wait for 3 seconds to see if onetrust is being used, if not just put the spotify embed on the page
			// console.log(' ------------- EMBED HERE ------------- ');
			// console.log(this.OptanonWrapper);
			// console.log(window.OptanonWrapper);
			// console.log(localStorage.OptIn_PreviousPermissions);
			//console.log(this.OptanonWrapper);
      // if (localStorage.OptIn_PreviousPermissions && JSON.parse(localStorage.OptIn_PreviousPermissions)['livefyre']){
      //   this.OptanonWrapper = false;
      // } else {
        var timer = setInterval( () => {
          if ( window.OptanonWrapper  || Date.now() > stop) {

              //console.log('****', window.OptanonWrapper , timer)
              clearInterval(timer);
              // timer = 0;
              this.OptanonWrapper = (window.OptanonWrapper ) ? window.OptanonWrapper : false; //saved into state for updating Vue
          }
        }, 50);
      //}
    },
    methods : {
      showSettings: function() {
          window.Optanon.ToggleInfoDisplay();
      },
    },
	}
</script>