<template>

    <div id="article">

        <article class="page" v-if="!article">

            <div class="row center">

                <div class="col twothirds">
                    <br>
                    <br>
                    <h4 class="bold center">Incorrect slug or can't find the FAC you requested.</h4>
                    <br>
                    <br>
               </div>

            </div>
        
        </article>

        <article class="page half" v-if="article">

            <div class="row fill-center">

                <div class="col twothirds">

                    <h2 class="bolder">{{article.title}}</h2>

                    <br>

                    <p>{{article.date}}</p>

                </div>

            </div>

        </article>
        
        <article class="page grey-lighter" v-if="article">


            <div class="row center" v-if="article">

                <div class="col twothirds">

                    <div class="row">

                         <div class="col">

                            <br>
                            <br>


                            <v-image
                                :gradient="false"
                               
                                :src="'/uploads/' + article.hero"
                            ></v-image>


                            <br>
                            <br>

                            <p class="article-body" v-html="article.body" style="white-space: pre-line;">{{article.body}}</p>

                            <br>
<!-- 
                            <p class="title" v-if="article.credit && article.credit != '' ">Credit: {{article.credit}}</p>
 -->
                            <!-- <br v-if="article.credit"> -->

                        </div>

                    </div>

                </div>
            </div>

        </article>


        <article class="page grey-lighter navh" v-if="article">

            <div class="row center" >

                <div class="col twothirds border-top-thick">

                    <div class="row">

                        <div class="col three">

                            
                            <h4 class="bold pad">Share this Article</h4>

                        </div>

                        <div class="col six">
                            
                            
                        </div>

                        <div class="col three">

                            <v-networks-share
                                class="right"
                                v-if="allowShare"
                                :share="share"
                                :links="links"
                            ></v-networks-share>
                            
                        </div>

                    </div>

                </div>
            </div>

        </article>

        <article class="page navh">

            <div class="row fill-center">

                <div class="col twothirds">

                    <div class="row">

                        <div class="col six pointer" v-on:click.prevent="onBack">

                            <h5 class="bolder back"> <i class="icon-left box"></i>&nbsp;&nbsp;Back to all news</h5>

                        </div>

                        <div class="col six">
                            
                            
                        </div>

                        <div class="col three">

                            
                        </div>

                    </div>

                </div>

            </div>

        </article>


    </div>

</template>

<script>

    import axios from 'axios';
    import VueAxios from 'vue-axios';

    import Grill from '@/Components/partials/Grill';
    import Title from '@/Components/partials/Title';
    import Button from '@/Components/partials/Button';
    import Image from '@/Components/partials/Image';
    import ArtistBlock from '@/Components/partials/ArtistBlock';
    import ImageBlock from '@/Components/partials/ImageBlock';
    import VideoBlock from '@/Components/partials/VideoBlock';
    import ImageBox from '@/Components/partials/ImageBox';
    import TweetBox from '@/Components/partials/TweetBox';
    import BookEnd from '@/Components/partials/BookEnd';
    import NewsLetter from '@/Components/partials/NewsLetter';
    import Search from '@/Components/partials/Search';
    import NetworksShare from '@/Components/partials/NetworksShare';

    export default {
        name: 'Article',
        props:[ 'links', 'share'],
        data: () => ({
            moreArticles: true, //going live with one article
            allowShare : true,
            article : false,
            nextArticle : false,
            placeholder : {
                video : 'https://placeimg.com/854/480/anima/grayscale', //16:9
                square : 'https://placeimg.com/640/640/animal/grayscale',
                rectangle : 'https://placeimg.com/844/616/animal/grayscale'
            }
        }),

        components: {
            'v-button': Button,
            'v-image': Image,
            'v-artist-block': ArtistBlock,
            'v-video-block': VideoBlock,
            'v-image-block': ImageBlock,
            'v-image-box': ImageBox,
            'v-tweet-box': TweetBox,
            'v-grill': Grill,
            'v-title': Title,
            'v-book-end': BookEnd,
            'v-newsletter' : NewsLetter,
            'v-search' : Search,
            'v-networks-share' : NetworksShare,
        },

        created() {

            let slug = ( this.$route.params.slug ) ? this.$route.params.slug : '40th-anniversary-boxed-sets-and-new-exhibition';

            if( !this.$route.params.slug ){
                this.$router.push('news/' +slug);
            }


            var self = this;
            this.getArticle( slug, function(data){
                console.log( data );
                self.article = data;
            });
            this.getNextArticle( slug, function(data){
                self.nextArticle = data;
            });
        },

        methods: {

            getArticle:function( slug, callback ){

                /*

                let data = require('@/assets/data/articles.json');

                data = data.filter(function(item) {
                    return item.slug == slug;
                });

                return ( data[0] ) ? data[0] : false;
                */


                axios.get('/data/articles.json').then(response => {

                    let data = response.data.articles;

                    data = data.filter(function(item) {
                        return item.slug == slug;
                    });

                    if ( data && callback ) callback( data[0] );

                });

            },

            onBack :function( evt ){
                this.$router.push("/news" );
            },

            getNextArticle:function( slug, callback ){

                if( !this.article ){
                    return false;
                }

                return false;

                /*

                let data = require('@/assets/data/articles.json');

                let index = this.article.id;

                return ( data[ index ] ) ? data[index] : false;
                */

                axios.get('/data/articles.json').then(response => {

                    let data = response.data.articles;

                    let index = this.article.id;

                     if ( data && callback ) callback(data[index]);

                });

            }

        }
    }
</script>