import Vue from 'vue'
import Router from 'vue-router'

// Page content
import Home from '@/components/pages/Home'
import Menu from '@/components/pages/Menu'
import Stylekit from '@/components/pages/Stylekit'
import Store from '@/components/pages/Store'
import News from '@/components/pages/News'
import Article from '@/components/pages/Article'
import Fac from '@/components/pages/Fac'
import Playlists from '@/components/pages/Playlists'
import Exhibition from '@/components/pages/Exhibition'
import Terms from '@/components/pages/Terms'
import Privacy from '@/components/pages/Privacy'

// Fallback page
import PageNotFound from '@/components/pages/404'

Vue.use(Router)

export default new Router({
    mode : 'history', //Use History like this... https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
    routes: [
        {
            path: '/',
            name: 'Home',
            track : 'test/home',
            component: Home,
            meta:{
               pageName: "Factory Records:Homepage",
               primaryCategory: "Factory Records:Homepage",
               pageType: "Homepage",
            }
        },

        {
            path: '/store',
            name: 'Store',
            component: Store,
            meta:{
               pageName: "Factory Records:Store",
               primaryCategory: "Factory Records:Store",
               pageType: "Store",
            }
        },

        {
            path: '/news',
            name: 'News',
            component: News,
                meta:{
                   pageName: "Factory Records:News",
                   primaryCategory: "Factory Records:News",
                   pageType: "News:Landing",
                }
        },

        // { IF ONLY ONE ARTICLE
        //     path: '/news',
        //     name: 'News',
        //     component: Article,
        //     meta:{
        //        pageName: "Factory Records:News",
        //        primaryCategory: "Factory Records:News",
        //        pageType: "News:Landing",
        //     }
        // },
        
        {
            path: '/news/:slug',
            name: 'Article',
            component: Article,
            meta:{
               pageName: "Factory Records:News:{SLUG}" ,
               primaryCategory: "Factory Records:News",
               pageType: "News:Article",
            }
      
        },
        {
            path: '/playlists',
            name: 'Playlists',
            component: Playlists,
            meta:{
               pageName: "Factory Records:Playlists",
               primaryCategory: "Factory Records:Playlists",
               pageType: "Playlists:Landing",
            }
        },
        {
            path: '/playlists/:slug',
            name: 'Playlist',
            component: Playlists,
            meta:{
               pageName: "Factory Records:Playlists:{SLUG}" ,
               primaryCategory: "Factory Records:Playlists",
               pageType: "Playlists:Details",
            }
        },
        {
            path: '/exhibition',
            name: 'Exhibition',
            component: Exhibition,
            meta:{
               pageName: "Factory Records:Exhibition:" ,
               primaryCategory: "Factory Records:Exhibition",
               pageType: "Exhibition:Landing",
            }
        },

        {
            path: '/exhibition/favourites',
            name: 'Favourites',
            component: Exhibition,
            meta:{
               pageName: "Factory Records:Exhibition:"  ,
               primaryCategory: "Factory Records:Exhibition",
               pageType: "Exhibition:Favourites",
            }
        },
        {
            path: '/exhibition/:slug',
            name: 'Fac',
            component: Fac,
            meta:{
               pageName: "Factory Records:Photos:{SLUG}" ,
               primaryCategory: "Factory Records:Exhibition",
               pageType: "Exhibition:Fac",
            }
      
        },
        {
            path: '/menu',
            name: 'Menu',
            component: Menu
        },
        // {
        //     path: '/privacy-policy',
        //     name: 'Privacy',
        //     component: Privacy
        // },
        // {
        //     path: '/terms-and-conditions',
        //     name: 'Terms',
        //     component: Terms
        // },
        // {
        //     path: '/stylekit',
        //     name: 'Stylekit',
        //     component: Stylekit
        // },
        {
            path: '**',
            name: 'PageNotFound',
            component: PageNotFound
        }
    ]

})