<template>

	<div class="grill">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>

</template>

<script>
	export default {
		name : 'Grill',
		props : [  ]
	}
</script>