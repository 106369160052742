<template>

	<div class="image">

		<div class="abs gradient" v-if="gradient"></div>
        <img class="fill" :src="src">
	    
	</div>

</template>

<script>
	export default {
		name : 'GradImage',
		props : {
			src : {
				type : String,
				required : true
			},
			gradient : {
				type : Boolean,
				default : false
			}
		}
	}
</script>