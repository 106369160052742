<template>
  	<section class="pages">
  		<router-view
            :key="$route.path"
            :channel="channel"
            :share="share"
            :links="links"></router-view>
    </section>
</template>

<script>
	export default {
	    name: 'Pages',
        props: ['share', 'links', 'channel' ],
	   	created() {
            this.$emit('updated', this.$route.path );
        },
        updated() {
            this.$emit('updated', this.$route.path );
        },
	}
</script>