<template>

  	<footer>

        <div class="row reverse">

            <div class="col seven">

                <h4 class="bold">Connect</h4>

                <v-networks-connect
                    :right="false"
                    :links="links"
                ></v-networks-connect>

            </div>

            <div class="col five">

                <h4 class="bold">Mailling List</h4>

                <v-newsletter
                    :links="links"
                ></v-newsletter>

            </div>

            
        </div>

        <div class="row">
            
            <div class="col">

                <p class="sm">

                    <span class="copyright">© 2021 Warner Music UK LTD&nbsp;&nbsp;</span>

                    <a class="link inline" :href="links.terms" target="_blank" >T&C's</a>

                    &nbsp;&nbsp;/&nbsp;&nbsp;

                    <a class="link inline" :href="links.privacy" target="_blank">Privacy</a>

                    &nbsp;&nbsp;/&nbsp;&nbsp;

                    <a class="link inline" :href="links.cookies" target="_blank">Cookie Policy</a>

                    &nbsp;&nbsp;/&nbsp;&nbsp;

                    <a class="link inline ot-sdk-show-settings">Cookie Settings</a>
                </p>

            </div>
            
        </div>
     
    </footer>

</template>

<script>

    import NetworksConnect from '@/Components/partials/NetworksConnect';
    import NewsLetter from '@/Components/partials/NewsLetter';
    import Grill from '@/Components/partials/Grill';

	export default {
	    name: 'Footer',
	    props:['links'],
        components:{
            'v-networks-connect' : NetworksConnect,
            'v-newsletter' : NewsLetter,
            'v-grill' : Grill
        },
	    data: () => ({
            // rollover : false
        }),
	}
</script>