<template>

    <div id="fac" ref="fac">

        <article class="page" v-if="!fac">

            <div class="row center">

                <div class="col twothirds">
                    <br>
                    <br>
                    <h4 class="bold center">Incorrect slug or can't find the FAC you requested.</h4>
                    <br>
                    <br>
               </div>

            </div>
        
        </article>

        <article class="page half" v-if="fac">

            <div class="row fill-center">

                <div class="col twothirds">

                    <h2 class="heavy upcase">{{fac.slug.replace(/[^0-9](?=[0-9])/g, '$& ')}}</h2>

                    <br>

                    <h4 class="name bold">{{fac.title}}</h4>
                    <h4 class="artist bold">{{fac.artist}}</h4>

                    <br>

                    <div class="row">

                        <div class="col icons third pointer" v-bind:class="{selected : playing }"  v-on:click="toggleAudio">

                            <h5 class="bolder" v-if="!playing"> <i class="icon-sound border-full box"></i>&nbsp;&nbsp;Play Audio Guide</h5>
                            <h5 class="right bolder " v-if="playing"><i class="icon-sound box"></i>&nbsp;&nbsp;Stop Playing Audio</h5>
                            
                        </div>

                        <div class="col icons third pointer" v-bind:class="{selected : saved }" v-on:click="toggleSave"> 

                            <h5  class="bolder" v-if="!saved"><i class="icon-favourite box"></i>&nbsp;&nbsp;Favourite</h5>
                            <h5  class="bolder" v-if="saved"><i class="icon-favourite box"></i>&nbsp;&nbsp;Saved</h5>
                          
                        </div>

                    </div>

                </div>

            </div>

        </article>


        <article class="page fac grey-lighter" v-if="fac">

            <div class="row center" >

                <div class="col twothirds ">

                    <br>
                    <br>

                    <v-image
                        :gradient="false"
                        :src="'/uploads/' + fac.hero"
                    ></v-image>

                    <br>
                    <br>

                    <p style="white-space: pre-line;">{{fac.body}}</p>

                    <br>

                </div>

            </div>

        </article>

        <article class="page grey-lighter navh" v-if="fac">

            <div class="row center">

                <div class="col twothirds border-top-thick">

                    <div class="row">

                        <div class="col three">

                            <h4 class="bold pad">Share this FAC</h4>

                        </div>

                        <div class="col six">
                            
                            
                        </div>

                        <div class="col three">

                            <v-networks-share
                                class="right"
                                v-if="allowShare"
                                :share="share"
                                :links="links"
                            ></v-networks-share>
                            
                        </div>

                    </div>

                </div>
            </div>

        </article>

        <article class="page grey">

            <div class="row center">

                <div class="col twothirds">

                    <v-search>Search a FAC number to learn more</v-search>

                </div>

            </div>

        </article>

        <article class="page navh fac-nav" v-if="fac">

            <div class="row fill-center">

                <div class="col twothirds">

                    <div class="row align-center">

                        <div class="col icons three pointer" v-on:click.prevent="onPrevious">

                            <h5 v-if="fac.id != 1"><i class="icon-left box"></i><span class="bolder">&nbsp;&nbsp;Previous</span></h5>

                        </div>

                        <div class="col six pointer" v-on:click.prevent="onBack">
                            
                            <h5  class="bolder center">
                              <span class="bolder center underlined-on-hover">All FAC's</span>
                            </h5>

                        </div>

                        <div class="col icons three pointer" v-on:click.prevent="onNext">

                            <h5  class="bolder right" v-if="fac.id != 50"><span class="bolder">Next&nbsp;&nbsp;</span><i class="icon-right box"></i></h5>
                          
                        </div>

                    </div>

                </div>

            </div>

        </article>

    </div>

</template>

<script>


    import axios from 'axios';
    import VueAxios from 'vue-axios';

    import Grill from '@/Components/partials/Grill';
    import Title from '@/Components/partials/Title';
    import Button from '@/Components/partials/Button';
    import Image from '@/Components/partials/Image';
    import ArtistBlock from '@/Components/partials/ArtistBlock';
    import ImageBlock from '@/Components/partials/ImageBlock';
    import VideoBlock from '@/Components/partials/VideoBlock';
    import ImageBox from '@/Components/partials/ImageBox';
    import TweetBox from '@/Components/partials/TweetBox';
    import BookEnd from '@/Components/partials/BookEnd';
    import NewsLetter from '@/Components/partials/NewsLetter';
    import Search from '@/Components/partials/Search';
    import NetworksShare from '@/Components/partials/NetworksShare';

    export default {
        name: 'fac',
        props:[ 'share', 'links'],
        data: () => ({
            allowShare : true,
            fac : false,
            facts : [10,14,16,24,25,30,40,42,44,45,50],
            userFacs : [],
            saved : false,
            playing : false, 
            placeholder : {
                video : 'https://placeimg.com/854/480/anima/grayscale', //16:9
                square : 'https://placeimg.com/640/640/animal/grayscale',
                rectangle : 'https://placeimg.com/844/616/animal/grayscale'
            }
        }),
        components: {
            'v-button': Button,
            'v-image': Image,
            'v-artist-block': ArtistBlock,
            'v-video-block': VideoBlock,
            'v-image-block': ImageBlock,
            'v-image-box': ImageBox,
            'v-tweet-box': TweetBox,
            'v-grill': Grill,
            'v-title': Title,
            'v-book-end': BookEnd,
            'v-newsletter' : NewsLetter,
            'v-search' : Search,
            'v-networks-share' : NetworksShare,
        },


        mounted() {

            if (localStorage.getItem('userFacs')) {
                try {
                    this.userFacs = JSON.parse(localStorage.getItem('userFacs'));
                } catch(e) {
                    localStorage.removeItem('userFacs');
                }
            }

            this.$root.$on('forceFacUpdate', ( slug ) => {
              console.log(slug);
              //this.forceupdate = slug;
            });

            console.log( 'FAC MOUNTED', this.userFacs );

            let self = this;
            this.getFac( this.$route.params.slug, function(data){
                self.fac = data;
                if( self.userFacs.some(fac => fac.id === self.fac.id) ){
                    self.saved = true;
                }
            });

        },

        methods: {

            getFac:function( slug, callback ){

                /*
                let data = require('/data/facs.json');

                data = data.filter(function(item) {
                    return item.slug == slug;
                });

                return ( data[0] ) ? data[0] : false;
                */


                axios.get('/data/facs.json').then(response => {

                    let data = response.data.facs;

                    data = data.filter(function(item) {
                        return item.slug == slug;
                    });

                    if ( data && callback ) callback(data[0]);

                });

            },

            toggleAudio : function(){
                // this.playing = !this.playing; // TO DO: connect this to the player
                this.$root.$emit( 'triggerAudio', this.fac.id );
            },

            toggleSave : function(){

                this.saved = !this.saved;

                if( this.saved ){

                    this.userFacs.push(this.fac);
                    this.saveUserFacs();

                }else{

                    var removeIndex = this.userFacs.map(function(fac) { return fac.id }).indexOf(this.fac.id);
                    this.userFacs.splice(removeIndex, 1);
                    this.saveUserFacs();
                }

            },

            // onSearched : function( slug ){
            //     this.$router.push("/exhibition/" + slug );
            //     this.forceupdate = slug;
            // },

            onPrevious :function( evt ){


                let prev = (this.fac.id - 1);

                let slug = ( this.facts.includes(prev) ) ? "fact" + prev : "fac" + prev;


                this.$router.push("/exhibition/" + slug );
                this.forceupdate = slug;
                 //this.$root.$emit('scrollTop'); //Do this on each route change now
            },

            onBack :function( evt ){
                this.$router.push("/exhibition" );
            },

            onNext :function( evt ){


                let next = (this.fac.id + 1);

                let slug = ( this.facts.includes(next) ) ? "fact" + next : "fac" + next;


                this.$router.push("/exhibition/" + slug );
                this.forceupdate = slug;
               //this.$root.$emit('scrollTop'); //Do this on each route change now

           },

            saveUserFacs() {
                this.$root.$emit( 'savingUserFacs', this.userFacs.length );
                localStorage.removeItem('userFacs');
                const parsed = JSON.stringify(this.userFacs);
                localStorage.setItem('userFacs', parsed);
            }

        }
    }
</script>